<template>
  <div class="home">
    <div class="clearfix pb20">
      <el-page-header :icon="ArrowLeft" @back="backToDocumentList">
        <template #content>
          <span class="text-large font-600 mr-3"> 文档详情 </span>
        </template>
      </el-page-header>
    </div>

    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="documentFilter.keyword" placeholder="请输入文档关键词" clearable
                  @keyup.enter="getDocumentPreview"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getDocumentPreview">
          检索
        </el-button>
      </div>
      <div class="mt5 fr">
        <el-radio-group v-model="documentFilter.lang" @change="documentLangChange">
          <el-radio-button label="zh-CN" key="zh-CN">中文</el-radio-button>
          <el-radio-button :label="config.lang" :key="config.lang"
                           v-for="config in merchantServerLanguages">{{ config.name }}
          </el-radio-button>

        </el-radio-group>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="documentList.length === 0"></Empty>
      <el-table
        :data="documentList"
        class="home-list"
        style="width: 100%"
        @cell-click="cellHandleClick"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="160"
        >
        </el-table-column>
        <el-table-column label="内容">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.text">{{ scope.row.text }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="cellPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="cellPreviewContent.length === 0"></Empty>
        <div class="preview-cell">{{ cellPreviewContent }}</div>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>

    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { ArrowLeft } from '@element-plus/icons-vue'
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form
    const documentList = ref([])
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const merchantRuleFormRef = ref(null)

    const merchantRules = ref({})

    const getDocumentPreview = () => {
      loading.value = true
      const documentId = route.params.id
      console.log(documentId)
      authApi.getMerchantDocumentPreview(merchant.value.id, documentId, documentFilter).then(res => {
        console.log(res)
        documentList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    const searchName = ref(null)
    const documentFilter = reactive({
      keyword: '',
      lang: 'zh-CN',
    })

    const loading = ref(false)

    const configInfo = computed(() => {
      return store.getters.config
    })

    const handleClick = (tab, event) => {
      console.log(tab, event)
    }

    const cellPreviewDialog = ref(false)
    const cellPreviewContent = ref('')

    const cellHandleClick = (row, column, event, cell) => {
      console.log(row, column, event, cell)
      cellPreviewContent.value = row.text
      cellPreviewDialog.value = true
    }

    const backToDocumentList = () => {
      router.replace({
        name: 'MerchantFileDocument',
      })
    }

    const documentLangChange = (lang) => {
      documentFilter.lang = lang
      getDocumentPreview()
    }

    onMounted(() => {
      getDocumentPreview()
    })
    return {
      ArrowLeft,
      loading,
      documentList,
      merchant,
      merchantServerLanguages,
      merchantRuleFormRef,
      merchantRules,
      searchName,
      configInfo,
      documentFilter,
      documentLangChange,

      getDocumentPreview,
      handleClick,

      backToDocumentList,

      cellPreviewDialog,
      cellPreviewContent,
      cellHandleClick,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.document-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
